import React, { memo, useCallback } from "react";
import { IconArrowLeft } from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = useCallback(() => {
    if (location.key === "default") {
      navigate("/");
    } else {
      navigate(-1);
    }
  }, [navigate, location]);

  if (location.pathname === "/") return null;

  return (
    <button onClick={handleClick}>
      <IconArrowLeft className="h-6 w-6" />
    </button>
  );
};

export default memo(BackButton);
