import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import GradiantSeparator from "./GradiantSeparator.tsx";

const QuickSearchSection = ({
  sectionTitle,
  searches,
}: {
  sectionTitle: string;
  searches?: string[];
}) => {
  const navigate = useNavigate();

  if (!searches?.length) {
    return <></>;
  }

  const handleSearch = (search: string) => {
    navigate(`/search-results?q=${search}`);
  };

  return (
    <div className="overflow-visible">
      <div className="flex items-center gap-2 px-4">
        <h2 className="text-lg font-semibold">{sectionTitle}</h2>
        <GradiantSeparator />
      </div>
      <ul className="no-scrollbar flex gap-2 overflow-x-scroll px-4">
        {searches.map((search) => (
          <li key={search}>
            <button
              onClick={() => handleSearch(search)}
              className="mb-6 mt-4 select-none whitespace-nowrap rounded-full bg-white px-4 py-2 text-sm text-slate-600 shadow-pill active:bg-slate-300">
              {search}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(QuickSearchSection);
