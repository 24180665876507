import React, { useState } from "react";
import GroupListItem from "../components/GroupListItem.tsx";
import GlobalHeader from "../components/GlobalHeader/GlobalHeader.tsx";
import { GroupedProduct } from "../api/getGroupedProducts.ts";
import { useLocation } from "react-router-dom";

export default function ProductDetailPage() {
  const data: GroupedProduct[] = useLocation().state

  return (
    <>
      <GlobalHeader />
      <div className="mt-40 overflow-y-scroll">
        <GroupListItem product={data} detailView={true}/>
      </div>
    </>
  );
}
