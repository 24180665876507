import React, { memo } from "react";
import { Eta, HomePageDetailsResponse } from "../api/getHomePageDetails";
// import { IconClock } from "@tabler/icons-react";
import GradiantSeparator from "./GradiantSeparator.tsx";
import { twMerge } from "tailwind-merge";

const ServiceProviderSection = ({ eta }: { eta: HomePageDetailsResponse["eta"] }) => {
  if (!eta?.length) {
    return <></>;
  }

  const handleDivClick = (item: Eta) => {
    // Open a new webview or tab with the specified URL
    window.open(item.url, '_blank', 'noopener,noreferrer');
  };


  return (
    <div className="overflow-visible">
      <div className="flex items-center gap-2 px-4">
        <h2 className="text-lg font-semibold">Delivering at your location right now</h2>
        <GradiantSeparator />
      </div>
      <ul className="no-scrollbar flex gap-2 overflow-x-scroll px-4">
        {eta.map((item) => (
          <li
            key={item.platform}
            style={{ cursor: 'pointer'}} onClick={() => handleDivClick(item)}
            className={twMerge("mb-6 mt-4 flex flex-col justify-between overflow-hidden rounded-md bg-white shadow-pill")}>
            <div className="flex w-full items-center justify-center gap-1 py-1">
              {/* {item.open ?
              <IconClock size={14} stroke={2} className="text-text"/> : null} */}
              <p className="text-s text-text text-center font-bold">{item.eta ? item.eta : (item.open ? "N/A" : "CLOSED")}</p>
            </div>
            <img
              src={item.image}
              alt={item.platform}
              className="w-[100px] rounded-md object-cover"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(ServiceProviderSection);
