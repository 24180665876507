import { User } from "firebase/auth";
import React, { createContext, useState } from "react";

const AuthenticationContext = createContext({
  user: null,
  login: (_user: User) => {},
  logout: () => {},
});

const AuthenticationProvider = ({ children }) => {
  const storedUser = localStorage.getItem("user");
  const [user, setUser] = useState(storedUser ? JSON.parse(storedUser) : null);

  const login = (user) => {
    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
  };

  return (
    <AuthenticationContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;

export const useAuthentication = () => React.useContext(AuthenticationContext);
