import { Geolocation } from "../context/GeolocationContext";
import { API_CONSTANT } from "./APIConstants.ts";

export interface GroupedProduct {
    platform: { name: string; sla: string; storeId: string, icon: string };
    id: string;
    name: string;
    brand: string;
    available: boolean;
    images: string[];
    mrp: number;
    offer_price: number;
    unit_level_price: number;
    quantity: string;
    deeplink: string;
};

export interface GroupedProducts {
    data: GroupedProduct[]
};


export default async function getGroupedProducts(
  geolocation: Geolocation,
  query: string,
): Promise<GroupedProducts[]> {
  if (!geolocation) {
    throw new Error("Geolocation is not available");
  }

  return fetch(
    `${API_CONSTANT.API_URL}?lat=${geolocation.latitude}&lon=${geolocation.longitude}&type=groupsearch&query=${query}`,
  ).then((response) => response.json());
}
