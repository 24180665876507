import React from "react";
import GeolocationProvider from "./context/GeolocationContext.tsx";
import AuthenticationProvider from "./context/AuthenticationContext.tsx";
import { initFirebase } from "./services/firebase.ts";
import Router from "./Router.tsx";

initFirebase();

const App = () => {
  return (
    <div className="relative mx-auto h-screen max-w-[1000px] overflow-hidden border-x">
      <GeolocationProvider>
        <AuthenticationProvider>
          <Router />
        </AuthenticationProvider>
      </GeolocationProvider>
    </div>
  );
};

export default App;
