import { Geolocation } from "../context/GeolocationContext";
import { API_CONSTANT } from "./APIConstants.ts";

export interface ProductSearchSuggestion {
  name: string;
  subtitle: string;
  image: string;
}

export default async function getProductSearchSuggestions(
  geolocation: Geolocation,
  query: string,
): Promise<ProductSearchSuggestion[]> {
  if (!geolocation) {
    throw new Error("Geolocation is not available");
  }

  return fetch(
    `${API_CONSTANT.API_URL}?lat=${geolocation.latitude}&lon=${geolocation.longitude}&type=autosuggest&query=${query}`,
  ).then((response) => response.json());
}
