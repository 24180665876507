import React, { useCallback, useState } from "react";
import {
  GeolocationPrediction,
  getNameFromAddressComponents,
  useGeolocation,
} from "../context/GeolocationContext.tsx";
import throttle from "lodash.throttle";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import Loader from "../components/Loader.tsx";
import getPlaceDetailsByPlaceId from "../api/getPlaceDetailsByPlaceId.ts";
import getMapSuggestions from "../api/getMapSuggestions.ts";
import BackButton from "../components/BackButton.tsx";
import GradiantSeparator from "../components/GradiantSeparator.tsx";

const GeolocationPage = () => {
  const { geolocation, isLocating, locateMe, storeGeolocation } = useGeolocation();
  const navigate = useNavigate();
  const [isStoringCustomLocation, setIsStoringCustomLocation] = useState(false);
  const [locationPredictions, setLocationPredictions] = useState([] as GeolocationPrediction[]);
  const [predictionError, setPredictionError] = useState<string | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getInputLocationSuggestions = useCallback(
    throttle(async (input: string) => {
      try {
        const data = await getMapSuggestions(input);
        setLocationPredictions(data.predictions);
      } catch (error) {
        console.error("Error fetching location suggestions:", error);
        setPredictionError(error.message);
      }
    }, 1000),
    [],
  );

  const handleLocationSearchInput = (e) => {
    e.preventDefault();

    if (!e.target.value) {
      setLocationPredictions([]);
      setPredictionError(null);
      return;
    }

    if (e.target.value?.length > 1) {
      getInputLocationSuggestions(e.target.value);
    }
  };

  const onClickLocationPrediction = useCallback(
    (prediction: GeolocationPrediction) => {
      setIsStoringCustomLocation(true);
      getPlaceDetailsByPlaceId(prediction.place_id)
        .then((data) => {
          const location = data.result.geometry.location;
          storeGeolocation({
            latitude: location.lat,
            longitude: location.lng,
            formatted_address: data.result.formatted_address,
            place_id: data.result.place_id,
            name: getNameFromAddressComponents(data.result.address_components),
          });
          navigate("/");
        })
        .catch((error) => {
          console.error("Error fetching location details:", error);
        })
        .finally(() => {
          setIsStoringCustomLocation(false);
        });
    },
    [navigate, storeGeolocation],
  );

  const onClickLocateMe = () => {
    locateMe().then(() => {
      navigate("/");
    });
  };

  return (
    <div className="flex h-full flex-col">
      <div className="p-4">
        <div className="flex grow flex-col">
          <div className="flex grow flex-row gap-4">
            <BackButton />
            <input
              type="search"
              placeholder="Manually enter location"
              className="w-full cursor-pointer rounded-xl border border-action bg-white p-2 ps-8 focus:cursor-auto focus:outline-none"
              onInput={handleLocationSearchInput}
            />
          </div>
          <div className="mb-2 w-full p-2">
            <button
              onClick={onClickLocateMe}
              className={twMerge("mb-2 mt-4 w-full rounded-lg bg-action p-2 text-white")}>
              Use Current Location
            </button>
          </div>

          {predictionError ? (
            <div className="overflow-y-auto border-t">
              <p className="text-red-500 p-2">{predictionError}</p>
            </div>
          ) : locationPredictions.length ? (
            <div className="overflow-y-auto">
              {locationPredictions.map((prediction) => (
                <>
                  <button
                    key={prediction.place_id}
                    className="hover:bg-gray-100 w-full p-2 text-left"
                    onClick={() => onClickLocationPrediction(prediction)}>
                    <p>{prediction.structured_formatting.main_text}</p>
                    <p className="text-xs">{prediction.structured_formatting.secondary_text}</p>
                  </button>
                  <GradiantSeparator />
                </>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      {/* <div className="absolute bottom-4 flex flex-col text-slate-500">
          <p className="text-xs">Formatted Address: {geolocation?.formatted_address || "N/A"}</p>
          <p className="text-xs">Name: {geolocation?.name || "N/A"}</p>
          <p className="text-xs">Place ID: {geolocation?.place_id || "N/A"}</p>
          <p className="text-xs">Latitude: {geolocation?.latitude || "N/A"}</p>
          <p className="text-xs">Longitude: {geolocation?.longitude || "N/A"}</p>
        </div> */}
      {isLocating || isStoringCustomLocation ? (
        <div className="bg-black absolute inset-40 bg-opacity-30">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default GeolocationPage;
