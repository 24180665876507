import React from "react";
import { IconLoader2 } from "@tabler/icons-react";

export default function Loader() {
  return (
    <div className="flex h-40 items-center justify-center">
      <IconLoader2 className="h-10 w-10 animate-spin text-action" />
    </div>
  );
}
