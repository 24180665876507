import React, { createContext, useContext, useState, useCallback } from "react";
import getPlaceDetailsByCoordinates from "../api/getPlaceDetailsByCoordinates.ts";

export interface GeolocationPrediction {
  description: string;
  matched_substrings: {
    length: number;
    offset: number;
  }[];
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: {
      length: number;
      offset: number;
    }[];
    secondary_text: string;
  };
  terms: { offset: number; value: string }[];
  types: string[];
}

export type Geolocation = {
  latitude: number;
  longitude: number;
  place_id: string;
  formatted_address: string;
  name: string;
} | null;

type GeolocationError = string | null;

const GeolocationContext = createContext({
  geolocation: null as Geolocation,
  error: null as GeolocationError,
  userDeniedGeolocation: false,
  isLocating: false,
  locateMe: () => Promise.resolve(null) as Promise<Geolocation>,
  storeGeolocation: (_geolocation: Geolocation) => {},
  getNameFromAddressComponents: (_addressComponents: any[]) => "" as string,
});

const defaultGeolocation = {
  latitude: 12.9038,
  longitude: 77.6648,
  formatted_address: "Sobha Classic, Haralur, Bangalore",
  place_id: "xxx",
  name: "Sobha Classic, Haralur, Bangalore",
};


const errorMessageMap = {
  1: "User denied geolocation permission",
  2: "Geolocation position unavailable",
  3: "Geolocation timeout exceeded",
};

const GENERIC_ERROR_MESSAGE = "Something went wrong while fetching your location";

export const getNameFromAddressComponents = (addressComponents: any[]) => {
  return addressComponents
    ?.splice(0, 2)
    ?.map((component) => component.long_name)
    ?.join(", ");
};

const GeolocationProvider = ({ children }) => {
  const storedGeolocation = localStorage.getItem("geolocation");

  const [geolocation, setGeolocation] = useState<Geolocation>(
    storedGeolocation ? JSON.parse(storedGeolocation) : null,
  );
  const [error, setError] = useState<GeolocationError>(null);
  const [userDeniedGeolocation, setUserDeniedGeolocation] = useState(false);
  const [isLocating, setIsLocating] = useState(false);

  const storeGeolocation = useCallback((geolocation: Geolocation) => {
    setGeolocation(geolocation);
    localStorage.setItem("geolocation", JSON.stringify(geolocation));
  }, []);

  const locateMe: () => Promise<Geolocation> = useCallback(() => {
    return new Promise((resolve) => {
      setIsLocating(true);

      if (!navigator.geolocation) {
        setError("Geolocation is not supported by your browser");
        setIsLocating(false);
        return;
      }

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const { latitude, longitude } = position.coords;
            const data = await getPlaceDetailsByCoordinates(latitude, longitude);
            const currentGeolocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              formatted_address: data.results?.[0]?.formatted_address,
              place_id: data.results?.[0]?.place_id,
              name: getNameFromAddressComponents(data.results?.[0]?.address_components),
            };
            storeGeolocation(currentGeolocation);
            setIsLocating(false);
            resolve(currentGeolocation);
          } catch (error) {
            console.error("Error fetching location details:", error);
            storeGeolocation(defaultGeolocation);
            resolve(defaultGeolocation);
            // setError(GENERIC_ERROR_MESSAGE);
            setIsLocating(false);
          }
        },
        (error) => {
          console.warn("Error getting current Geolocation:", error);
          // setError(errorMessageMap[error.code] || GENERIC_ERROR_MESSAGE);
          storeGeolocation(defaultGeolocation);
          resolve(defaultGeolocation);

          // if (error.code === 1) {
          //   setUserDeniedGeolocation(true);
          //   window.alert(
          //     "Turn on Location Services to allow us to determine your location. Click the Location icon in the Address bar, and then Always allow.",
          //   );
          // }

          setIsLocating(false);
        },
      );
    });
  }, [storeGeolocation]);

  return (
    <GeolocationContext.Provider
      value={{
        geolocation,
        error,
        userDeniedGeolocation,
        isLocating,
        locateMe,
        storeGeolocation,
        getNameFromAddressComponents,
      }}>
      {children}
    </GeolocationContext.Provider>
  );
};

export default GeolocationProvider;

export const useGeolocation = () => useContext(GeolocationContext);
