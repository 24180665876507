import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Loader from "../components/Loader.tsx";
import GroupListItem from "../components/GroupListItem.tsx";
import { useGeolocation } from "../context/GeolocationContext.tsx";
import GeolocationNotAvailable from "../components/GeolocationNotAvailable.tsx";
import GlobalHeader from "../components/GlobalHeader/GlobalHeader.tsx";
import getGroupedProducts, { GroupedProducts } from "../api/getGroupedProducts.ts";

export default function GroupListPage() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([] as GroupedProducts[]);
  const [searchParams] = useSearchParams();
  const { geolocation, isLocating } = useGeolocation();
  const query = searchParams.get("q") || "";

  useEffect(() => {
    if (!geolocation) {
      return;
    }

    setLoading(true);
    getGroupedProducts(geolocation, query)
      .then((data) => {
        data.sort((a, b) => b.data.length - a.data.length);
        setProducts(data);
      })
      .catch((error) => console.error("Error fetching products:", error))
      .finally(() => setLoading(false));
  }, [query, geolocation]);

  return (
    <>
      <GlobalHeader />
      <div className="mt-40 overflow-y-scroll">
      {!geolocation ? (
        <GeolocationNotAvailable />
      ) : loading || isLocating ? (
        <Loader />
      ) : products.length ? (
        <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 px-4 pb-4">
          {products.map((product, index) => (
            <GroupListItem key={index} product={product.data} detailView={false}/>
          ))}
        </div>
      ) : (
        <div className="flex h-full flex-col items-center justify-center">
          <h1 className="text-center text-2xl font-bold">No products found</h1>
          <p className="text-center text-gray-500">
            {"Try searching for something else or "}
            <br />
            <Link to="/geolocation" className="text-blue-500 underline">
              {"change your location"}
            </Link>
            {"."}
          </p>
        </div>
      )}
      </div>
    </>
  );
}
