import React, { useEffect, useState } from "react";
import { useGeolocation } from "../context/GeolocationContext.tsx";
import getHomePageData, { HomePageDetailsResponse } from "../api/getHomePageDetails.ts";
import Loader from "../components/Loader.tsx";
import GeolocationNotAvailable from "../components/GeolocationNotAvailable.tsx";
import QuickSearchSection from "../components/QuickSearchSection.tsx";
import ServiceProviderSection from "../components/ServiceProviderSection.tsx";
import GlobalHeader from "../components/GlobalHeader/GlobalHeader.tsx";
// import { HomeProducts } from "../meta/HomeJson.ts";

const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const { locateMe, geolocation, isLocating } = useGeolocation();
  const [homePageDetails, setHomePageDetails] = useState<HomePageDetailsResponse | null>(null);
  const storedRecentSearches = localStorage.getItem("recentSearches") || "[]";
  const recentSearches = JSON.parse(storedRecentSearches);
  console.log("url", process.env.API_URL);
  // const homeProducts = HomeProducts;

  useEffect(() => {
    if (!geolocation) {
      locateMe();
    }
  }, [geolocation, locateMe]);

  useEffect(() => {
    if (!geolocation) {
      return;
    }

    setLoading(true);
    getHomePageData(geolocation.latitude, geolocation.longitude)
      .then(setHomePageDetails)
      .catch((error) => {
        console.error("Error fetching home page data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [geolocation]);

  return (
    <>
      <GlobalHeader />
      <div className="mt-44 overflow-y-scroll py-4">
        <h1 className="text-action mx-4 mb-4 text-4xl font-bold">Compare grocery needs realtime</h1>
        {loading || isLocating ? (
          <Loader />
        ) : !geolocation ? (
          <GeolocationNotAvailable />
        ) : homePageDetails ? (
          <>
            <div>
              <QuickSearchSection sectionTitle="Recent Search" searches={recentSearches} />
              <QuickSearchSection sectionTitle="Trending" searches={homePageDetails.trending} />
              {/* <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 px-4 pb-4">
                {homeProducts.map((product, index) => (
                  <GroupListItem key={index} product={product.data} />
              ))}
              </div> */}
              <ServiceProviderSection eta={homePageDetails.eta} />
            </div>
            <div className="bg-bg-dark text-bg mx-4 mb-48 mt-4 rounded-md px-6 py-4">
              <h2 className="mb-3 text-2xl font-semibold">Save Money, Every Day.</h2>
              <h3 className="text-lg font-normal">
                Compare prices of your daily essentials in seconds.
              </h3>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default HomePage;
